body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

aside {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

aside::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background-color: @white;
}

::-webkit-scrollbar-thumb {
    background-color: @primary-color;
    border-radius: 8px;
}


.ant-drawer-body {
    .ant-base-sider {
        min-height: calc(100% - @layout-header-height);
        height: calc(100% - @layout-header-height);
    }
}
