.ant-form-item-label > label .ant-form-item-tooltip {
    margin-left: 4px;
}

.ant-select-selection-overflow-item {
    margin: 0 2px;
}

.ant-image-mask {
    background: @modal-mask-bg;
}
