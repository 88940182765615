.ant-base-sider {
    background-color: @layout-sider-background;
    position: fixed;
    z-index: 10;
    overflow: auto;
    left: 0;
    box-shadow: @shadow-1-right;
    min-height: 100%;
    height: 100%;
}
