.ant-base-header {
    padding: 0;
    display: flex;
    position: relative;
    height: @layout-header-height;
    background: @body-background;

    &-trigger {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: @layout-header-height;
        cursor: pointer;

        &:hover {
            background: rgba(0, 0, 0, .025);
            color: @primary-color;
        }

        svg {
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            transition: transform 0.4s;
            user-select: none;

            &.active {
                transform: rotate(180deg);

                .top {
                    stroke-dasharray: 14 82;
                    stroke-dashoffset: -72px;
                }

                .bottom {
                    stroke-dasharray: 14 82;
                    stroke-dashoffset: -72px;
                }
            }

            .top {
                stroke-dasharray: 40 82;
            }

            .bottom {
                stroke-dasharray: 40 82;
            }

            .line {
                fill: none;
                transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                stroke: @black;
                stroke-width: 5;
                stroke-linecap: round;
            }
        }
    }
}
