.ant-menu.ant-menu-sub.ant-menu-vertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 4px 0;
    border-right: 0;

    .ant-menu-item {
        width: 100%;
        justify-content: flex-start;
    }
}
